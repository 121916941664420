<script setup lang="ts">
import { DealTalentSelection } from '@/gql/Gql.types';
import Button from '@/components/atoms/Button.vue';
import Select from '@/components/atoms/Select.vue';
import { getDeal } from '@/api/queries/deal';
import { useRouteParams } from '@vueuse/router';
import { computed, defineEmits, ref, defineProps } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { RouterLink } from 'vue-router';
const tab = useRouteQuery<string>('tab');

const emit = defineEmits([
  'openStatusModal',
  'setSelectedTalent',
  'setSelectedTalentStatus',
  'openNotesModal',
  'openEditModal',
  'openTalentCountModal',
  'deleteTalentSelection',
  'setTalentCountModalStatus',
]);

const id = useRouteParams<string>('id');

const props = defineProps<{
  talent: DealTalentSelection;
  resetSelect: number;
}>();

const { data: deal } = getDeal({
  id: id.value,
});

const cvUrl = computed(() => props.talent?.talent.documents?.find((doc) => doc.type === 'CV')?.url);

const portfolioUrl = computed(
  () => props.talent?.talent.documents?.find((doc) => doc.type === 'PORTFOLIO')?.url
);

const selectOptions = ref([
  {
    value: 'UNCHECKED',
    label: 'Long List',
  },
  {
    value: 'REJECTED',
    label: 'Rejected',
  },
  {
    value: 'NOT_AVAILABLE',
    label: 'Not Avaliable',
  },
  {
    value: 'MAYBE',
    label: 'Maybe',
  },
  {
    value: 'SHORTLIST',
    label: 'Shortlist',
  },
]);
</script>

<template>
  <div class="talent">
    <div class="info">
      <div class="indicators">
        <span class="manuallyAdded" v-if="props.talent.manuallyAdded">Manually Added</span>
        <span v-if="props.talent.manuallyAdded && !props.talent.talent.zohoId">-</span>
        <span class="noZohoId" v-if="!props.talent.talent.zohoId">Missing Zoho ID</span>
      </div>
      <RouterLink class="name" target="_blank" :to="`/talents/edit/${props.talent.talent.id}`"
        >{{ props.talent.talent?.firstName }} {{ props.talent.talent?.lastName }}</RouterLink
      >
      <span class="email">{{ props.talent.talent?.email }}</span>
      <span class="mobile">{{ props.talent.talent?.mobile }}</span>
    </div>
    <div class="freelance">
      <span v-if="props.talent.talent.freelance === 'Sì'">FREELANCE</span>
    </div>
    <div class="counts">
      <span
        class="longListBadge"
        @click="
          () => {
            emit('setTalentCountModalStatus', 'longlist');
            emit('openTalentCountModal');
            emit('setSelectedTalent', props.talent.id);
          }
        "
        >{{ props.talent.longListCount.length }}</span
      >

      <span
        class="rejectedBadge"
        @click="
          () => {
            emit('setTalentCountModalStatus', 'rejected');
            emit('openTalentCountModal');
            emit('setSelectedTalent', props.talent.id);
          }
        "
        >{{ props.talent.rejectedCount.length }}</span
      >

      <span
        class="notAvailableBadge"
        @click="
          () => {
            emit('setTalentCountModalStatus', 'notAvailable');
            emit('openTalentCountModal');
            emit('setSelectedTalent', props.talent.id);
          }
        "
        >{{ props.talent.notAvailableCount.length }}</span
      >

      <span
        class="maybeBadge"
        @click="
          () => {
            emit('setTalentCountModalStatus', 'maybe');
            emit('openTalentCountModal');
            emit('setSelectedTalent', props.talent.id);
          }
        "
        >{{ props.talent.maybeCount.length }}</span
      >

      <span
        class="shortlistBadge"
        @click="
          () => {
            emit('setTalentCountModalStatus', 'shortlist');
            emit('openTalentCountModal');
            emit('setSelectedTalent', props.talent.id);
          }
        "
        >{{ props.talent.shortlistCount.length }}</span
      >
    </div>
    <div class="statusSelect">
      <Select
        :options="selectOptions"
        :initial-value="selectOptions.find((option) => option.value === props.talent.status)"
        size="small"
        @selected="
          (value) => {
            emit('openStatusModal', true);
            emit('setSelectedTalentStatus', value.value);
            emit('setSelectedTalent', props.talent.id);
          }
        "
        :key="props.resetSelect"
        :disabled="
          deal?.deal.isArchived ||
          Boolean(deal?.deal.sentAt) ||
          !props.talent.agreedRatecard ||
          !props.talent.talentPresentation
        "
      />
    </div>

    <div class="links">
      <Button
        icon="search-doc"
        label="CV"
        variant="link-tertiary"
        :to="cvUrl"
        tag="a"
        target="_blank"
      />
      <Button
        icon="search-doc"
        label="Portfolio"
        variant="link-tertiary"
        :to="portfolioUrl"
        tag="a"
        target="_blank"
        :disabled="!portfolioUrl"
      />
      <Button
        icon="search-doc"
        label="Notes"
        variant="link-tertiary"
        @click="
          () => {
            emit('openNotesModal', true);
            emit('setSelectedTalent', props.talent.id);
          }
        "
      />
    </div>
    <div class="ratecard">
      <div>
        <span class="title">Ratecard Range</span>
        <span class="values">{{ props.talent.talent.rate || '-' }}</span>
      </div>
      <div>
        <span class="agreedRatecardTitle">Agreed Ratecard</span>
        <span class="agreedRatecard">{{
          props.talent.agreedRatecard ? '€ ' + props.talent.agreedRatecard : '-'
        }}</span>
      </div>
    </div>
    <div class="actions">
      <div class="actions-left">
        <Button
          icon="idcard"
          variant="accent"
          :disabled="deal?.deal.isArchived"
          @clicked="
            () => {
              emit('openEditModal', true);
              emit('setSelectedTalent', props.talent.id);
            }
          "
        />

        <Button
          v-if="tab === 'POOL'"
          icon="delete"
          variant="danger"
          :disabled="deal?.deal.isArchived || Boolean(deal?.deal.sentAt)"
          @clicked="
            () => {
              emit('deleteTalentSelection', [props.talent.id]);
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.talent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 3rem 0;
  border-bottom: 1px solid $black-10;
  gap: 3rem;
  .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 15%;
    .name {
      font-size: 1.6rem;
      font-weight: $primary-font-weight;
      text-decoration: none;
      color: black;
      &:hover {
        text-decoration: underline;
      }
    }

    .email,
    .mobile {
      font-size: 1.3rem;
      color: $black-50;
    }

    .indicators {
      display: flex;
      gap: 0.2rem;
    }

    .manuallyAdded {
      font-size: 1.3rem;
      color: rgba($blue, 0.75);
    }

    .noZohoId {
      font-size: 1.3rem;
      color: rgba($red, 0.75);
    }
  }

  .freelance {
    font-size: 1.3rem;
    color: $blue;
    width: 6rem;
  }

  .counts {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    .longListBadge {
      background-color: #ede9fe;
      color: #6d28d9;
      padding: 0.7rem;
      border-radius: 9999px;
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        cursor: pointer;
      }
    }

    .rejectedBadge {
      background-color: #fce7f3;
      color: #a21caf;
      padding: 0.7rem;
      border-radius: 9999px;
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
    .notAvailableBadge {
      background-color: #ecfccb;
      color: #4d7c0f;
      padding: 0.7rem;
      border-radius: 9999px;
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
    .maybeBadge {
      background-color: #fef3c7;
      color: #a16207;
      padding: 0.7rem;
      border-radius: 9999px;
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
    .shortlistBadge {
      background-color: #e0f2fe;
      color: #0369a1;
      padding: 0.7rem;
      border-radius: 9999px;
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .statusSelect {
    width: 18rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .ratecard {
    display: flex;
    gap: 4rem;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .title {
        font-size: 1.3rem;
        color: $black-50;
      }
      .values {
        font-size: 1.3rem;
        font-weight: $primary-font-weight;
      }
      .agreedRatecardTitle {
        font-size: 1.3rem;
        color: $black-50;
      }
      .agreedRatecard {
        font-size: 1.3rem;
        font-weight: $primary-font-weight;
      }
    }
  }

  .actions-left {
    display: flex;
    gap: 1rem;
  }
}
</style>
