import { gql } from '@/api/graphql-client';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type {
  DealTalentSelectionConnection,
  DealTalentSelectionsFilterInput,
  QueryDealTalentSelectionsArgs,
} from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { ref, watch, type Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useQuery } from '@tanstack/vue-query';

type FetcherProps<TVariables> = {
  query: string;
  variables?: TVariables;
  headers?: HeadersInit;
};

export const fetcher = async <TResult, TVariables>({
  query,
  variables,
  headers,
}: FetcherProps<TVariables>): Promise<TResult> => {
  if (!import.meta.env.VITE_GRAPHQL_DEV) {
    throw new Error(`Missing correct key .env file`);
  }

  const response: Response = await fetch(import.meta.env.VITE_GRAPHQL_DEV, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (response.status !== 200) {
    throw new Error(`Error: ${response.statusText}. Body: ${await response.text()}`);
  }

  const json = await response.json();

  if (!json.data && json.errors.length > 0) {
    const { extensions, message } = json.errors[0];

    throw new Error(`Error: ${extensions.code}. Body: ${message}`);
  }

  return json.data;
};

export const dealTalentSelectionQuery = gql`
  query getDealTalentSelections(
    $first: Int
    $filters: DealTalentSelectionsFilterInput
    $after: String
  ) {
    dealTalentSelections(filters: $filters, first: $first, after: $after) {
      nodes {
        note
        status
        id
        agreedRatecard
        manuallyAdded
        talentPresentation
        talent {
          id
          email
          firstName
          lastName
          mobile
          documents {
            documentId
            type
            url
          }
          rate
          zohoId
          freelance
        }
        maybeCount
        shortlistCount
        longListCount
        notAvailableCount
        rejectedCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const getDealTalentSelection = (
  props?: QueryDealTalentSelectionsArgs & {
    params?: Ref<{ first?: number; last?: number; after?: string; before?: string }>;
  }
): UseQueryReturnType<
  {
    dealTalentSelections: DealTalentSelectionConnection;
  },
  QueryDealTalentSelectionsArgs
> => {
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);
  const retries = ref<number>(0);

  const keys = [dealTalentSelectionQuery, props];

  const fn = () => {
    return fetcher<string, {}>({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
      },
      query: dealTalentSelectionQuery,
      variables: {
        filters: {
          dealId: (props as any)?.filters?.dealId.value,
          talentId: (props as any)?.filters?.talentId && (props as any)?.filters?.talentId.value,
        },
      },
    });
  };

  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: 1,
  };

  const request = useQuery<
    {
      deals: DealTalentSelectionConnection;
    },
    QueryDealTalentSelectionsArgs
  >(keys, fn as any, queryOptions);

  watch([request.error], async () => {
    const isUnauthorized = `${request.error.value as string}`
      .toLowerCase()
      .split(' ')
      .some((el) => ['unauthorized', 'unauthenticated'].includes(el));

    if (retries.value < 1 && isUnauthorized) {
      await userStore.refreshToken(jwt.value);
      await request.refetch();
      return retries.value++;
    }

    if (isUnauthorized) {
      await userStore.logout();
    }
  });

  return request as UseQueryReturnType<
    {
      dealTalentSelections: DealTalentSelectionConnection;
    },
    QueryDealTalentSelectionsArgs
  >;
};
