<script setup lang="ts">
import { getDealTalentSelection } from '@/api/queries/dealTalentSelection';
import Tab from '@/components/atoms/Tab.vue';
import { computed, onMounted, ref, watch, defineProps } from 'vue';
import { useRouteParams, useRouteQuery } from '@vueuse/router';
import Talent from './Talent.vue';
import Modal from '@/components/atoms/Modal.vue';
import Button from '@/components/atoms/Button.vue';
import { useUpdateDealTalentSelection } from '@/api/mutations/updateDealTalentSelection';
import { DealTalentSelectionStatus } from '@/gql/Gql.types';
import InputText from '@/components/atoms/InputText.vue';
import Notification, { type INotificationProps } from '@/components/atoms/Notification.vue';
import { Deal } from '@/gql/Gql.types';
import { useDeleteDealTalentSelection } from '@/api/mutations/deleteTalentSelection';
import { useSendDealSelection } from '@/api/mutations/sendDealSelection';
import { useAutoMatching } from '@/api/mutations/autoMatching';
import TalentCountModal from './talentCountModal/TalentCountModal.vue';

const props = defineProps<{
  deal?: Deal;
}>();

const isChangeStatusModalActive = ref(false);
const isNoteModalActive = ref(false);
const isEditModalActive = ref(false);
const isDeleteModalActive = ref(false);
const isSendDealModalActive = ref(false);
const isAutoMatchingModalActive = ref(false);
const deleteIds = ref([]);
const selectedTalent = ref('');
const selectedTalentStatus = ref('');
const talentNotes = ref('');
const talentPresentation = ref('');
const agreedRatecard = ref(0);

const resectSelect = ref(0);

const setSelectedTalentStatus = (value: string) => {
  selectedTalentStatus.value = value;
};

const id = useRouteParams('id');
const tab = useRouteQuery('tab');

const setTab = (value: string) => {
  tab.value = value;
};

const { data: talentsSelection } = getDealTalentSelection({
  filters: {
    dealId: id as unknown as string,
  },
});

const { mutate: updateDealTalentSelection, isError, isSuccess } = useUpdateDealTalentSelection();

const {
  mutate: sendDealSelection,
  isError: isSendDealError,
  isSuccess: isSendDealSuccess,
} = useSendDealSelection();

const {
  mutate: performAutoMatching,
  isError: isAutoMatchingError,
  isSuccess: isAutoMatchingSuccess,
  isLoading: isAutoMatchingLoading,
} = useAutoMatching();

watch([isSendDealSuccess, isSendDealError], () => {
  if (isSendDealSuccess.value) {
    notificationMessage.value = 'Deal sent successfully';
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isSendDealError.value) {
    notificationMessage.value = 'Error sending deal';
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});

watch([isAutoMatchingSuccess, isAutoMatchingError], () => {
  if (isAutoMatchingSuccess.value) {
    notificationMessage.value = 'Auto matching completed successfully';
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isAutoMatchingError.value) {
    notificationMessage.value = 'Error performing auto matching';
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});

const notificationMessage = ref('');
const notificationStatus = ref<INotificationProps['status']>('');
const showNotification = ref(false);

watch([isSuccess, isError], () => {
  if (isSuccess.value) {
    notificationMessage.value = 'Talent data updated successfully';
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isError.value) {
    notificationMessage.value = 'Talent data update failed';
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});

const {
  mutate: deleteTalentSelection,
  isSuccess: isDeleteTalentSuccess,
  isError: isDeleteTalentError,
} = useDeleteDealTalentSelection();

watch([isDeleteTalentSuccess, isDeleteTalentError], () => {
  if (isDeleteTalentSuccess.value) {
    notificationMessage.value = 'Talent deleted successfully';
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isDeleteTalentError.value) {
    notificationMessage.value = 'Error deleting talent';
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});

const uncheckedTalents = computed(() => {
  return talentsSelection.value?.dealTalentSelections.nodes?.filter(
    (talent) => talent.status === 'UNCHECKED'
  );
});

const rejectedTalents = computed(() => {
  return talentsSelection.value?.dealTalentSelections.nodes?.filter(
    (talent) => talent.status === 'REJECTED'
  );
});

const notAvailableTalents = computed(() => {
  return talentsSelection.value?.dealTalentSelections.nodes?.filter(
    (talent) => talent.status === 'NOT_AVAILABLE'
  );
});

const maybeTalents = computed(() => {
  return talentsSelection.value?.dealTalentSelections.nodes?.filter(
    (talent) => talent.status === 'MAYBE'
  );
});

const shortlistTalents = computed(() => {
  return talentsSelection.value?.dealTalentSelections.nodes?.filter(
    (talent) => talent.status === 'SHORTLIST'
  );
});

const talents = (status: string) => {
  switch (status) {
    case 'UNCHECKED':
      return uncheckedTalents.value;
    case 'REJECTED':
      return rejectedTalents.value;
    case 'NOT_AVAILABLE':
      return notAvailableTalents.value;
    case 'MAYBE':
      return maybeTalents.value;
    case 'SHORTLIST':
      return shortlistTalents.value;
    case 'POOL':
      return talentsSelection.value?.dealTalentSelections.nodes;
    default:
      return talentsSelection.value?.dealTalentSelections.nodes;
  }
};

onMounted(() => {
  if (!tab.value) {
    setTab('POOL');
  }
});

const tabItems = computed(() => {
  return [
    {
      name: 'POOL',
      label: `Pool ${
        talentsSelection.value?.dealTalentSelections.nodes?.length
          ? talentsSelection.value?.dealTalentSelections.nodes?.length
          : 0
      }`,
    },
    {
      name: 'UNCHECKED',
      label: `Long List ${
        uncheckedTalents.value?.length !== undefined ? uncheckedTalents.value?.length : 0
      }`,
    },
    {
      name: 'REJECTED',
      label: `Rejected ${
        rejectedTalents.value?.length !== undefined ? rejectedTalents.value?.length : 0
      }`,
    },
    {
      name: 'NOT_AVAILABLE',
      label: `Not Avaliable ${
        notAvailableTalents.value?.length !== undefined ? notAvailableTalents.value?.length : 0
      }`,
    },
    {
      name: 'MAYBE',
      label: `Maybe ${maybeTalents.value?.length !== undefined ? maybeTalents.value?.length : 0}`,
    },
    {
      name: 'SHORTLIST',
      label: `Shortlist ${shortlistTalents.value?.length}`,
    },
  ];
});

const missingZohoIds = computed(() => {
  return shortlistTalents.value?.filter((talent) => !talent.talent.zohoId).length === 0
    ? false
    : true;
});

const isTalentCountModalActive = ref(false);
const talentCountModalStatus = ref('');

const colors: Record<string, { bgColor: string; textColor: string }> = {
  POOL: {
    bgColor: 'black',
    textColor: 'white',
  },
  UNCHECKED: {
    bgColor: '#ede9fe',
    textColor: '#6d28d9',
  },
  REJECTED: {
    bgColor: '#fce7f3',
    textColor: '#a21caf',
  },
  NOT_AVAILABLE: {
    bgColor: '#ecfccb',
    textColor: '#4d7c0f',
  },
  MAYBE: {
    bgColor: '#fef3c7',
    textColor: '#a16207',
  },
  SHORTLIST: {
    bgColor: '#e0f2fe',
    textColor: '#0369a1',
  },
};
</script>

<template>
  <Notification
    v-if="showNotification"
    :status="notificationStatus"
    :message="notificationMessage"
    :duration="5000 as number"
  />
  <TalentCountModal
    :is-modal-active="isTalentCountModalActive"
    :talent="
      talentsSelection?.dealTalentSelections.nodes?.find((talent) => {
        return talent.id === selectedTalent;
      })
    "
    :status="talentCountModalStatus"
    @close-modal="
      () => {
        isTalentCountModalActive = false;
        talentCountModalStatus = '';
      }
    "
  />
  <Modal
    head=""
    :is-modal-active="isChangeStatusModalActive"
    @close-modal="isChangeStatusModalActive = false"
  >
    <template #modal-main>
      <div class="changeStatus">Are you sure you want to update the status of this talent?</div>
    </template>
    <template #modal-footer>
      <Button
        label="Cancel"
        variant="secondary"
        @click="
          () => {
            resectSelect += 1;
            isChangeStatusModalActive = false;
          }
        "
      />
      <Button
        label="Update status"
        @click="() => {
        updateDealTalentSelection({
          id: selectedTalent,
          data: {
            status: selectedTalentStatus as DealTalentSelectionStatus,
          },
        });
       
        isChangeStatusModalActive = false;
      }"
      />
    </template>
  </Modal>
  <Modal
    head=""
    :is-modal-active="isSendDealModalActive"
    @close-modal="isSendDealModalActive = false"
  >
    <template #modal-main>
      <div class="changeStatus" v-if="!missingZohoIds">
        Are you sure you want to send this talent selection to Zoho?
      </div>
      <div class="changeStatus" v-else>
        This talent selection contains talents with missing Zoho IDs. <br />Do you still want to
        send this talent selection to Zoho?
      </div>
    </template>
    <template #modal-footer>
      <Button
        label="Cancel"
        variant="secondary"
        @click="
          () => {
            isSendDealModalActive = false;
          }
        "
      />.
      <Button
        label="Send to Zoho"
        @click="() => { sendDealSelection({ id: props.deal?.id as
      string }); isSendDealModalActive = false; }"
      />
    </template>
  </Modal>
  <Modal head="" :is-modal-active="isDeleteModalActive" @close-modal="isDeleteModalActive = false">
    <template #modal-main>
      <div class="changeStatus">Are you sure you want to remove this talent?</div>
    </template>
    <template #modal-footer>
      <Button
        label="Cancel"
        variant="secondary"
        @click="
          () => {
            isDeleteModalActive = false;
          }
        "
      />
      <Button
        label="Delete"
        @click="
          () => {
            deleteTalentSelection({ ids: deleteIds });

            isDeleteModalActive = false;
          }
        "
      />
    </template>
  </Modal>
  <Modal
    head="TRS Matching"
    :is-modal-active="isAutoMatchingModalActive"
    @close-modal="isAutoMatchingModalActive = false"
  >
    <template #modal-main>
      <div>
        <p class="changeStatus">Are you sure you want to perform TRS Matching for this deal?</p>
      </div>
    </template>
    <template #modal-footer>
      <Button label="Cancel" variant="secondary" @click="isAutoMatchingModalActive = false" />
      <Button
        label="Yes"
        @click="
          () => {
            performAutoMatching({
              id: props.deal?.id as string
            });
            isAutoMatchingModalActive = false;
          }
        "
      />
    </template>
  </Modal>
  <Modal head="Notes" :is-modal-active="isNoteModalActive" @close-modal="isNoteModalActive = false">
    <template #modal-main>
      <div class="notes">
        <InputText
          type="textarea"
          class="note"
          :initial-value="
            talentsSelection?.dealTalentSelections.nodes?.find((talent) => {
              return talent.id === selectedTalent;
            })?.note
          "
          @keystart="(value) => (talentNotes = value)"
        />

        <Button
          label="Save"
          @click="() => {
            updateDealTalentSelection({
              id: selectedTalent,
              data: {
                note: talentNotes as string,
              },
            });
            isNoteModalActive = false;
          }"
        />
      </div>
    </template>
  </Modal>
  <Modal
    head=""
    :is-modal-active="isEditModalActive"
    @close-modal="isEditModalActive = false"
    class="editModal"
  >
    <template #modal-main>
      <div class="notes">
        <InputText
          label="Talent Presentation"
          type="textarea"
          class="note"
          :initial-value="
            talentsSelection?.dealTalentSelections.nodes?.find((talent) => {
              return talent.id === selectedTalent;
            })?.talentPresentation
          "
          @keystart="(value) => (talentPresentation = value)"
          :disabled="Boolean(deal?.sentAt)"
        />

        <InputText
          type="number"
          label="Agreed ratecard"
          :initial-value="
            talentsSelection?.dealTalentSelections.nodes?.find((talent) => {
              return talent.id === selectedTalent;
            })?.agreedRatecard
          "
          @keystart="(value) => (agreedRatecard = value)"
          :disabled="Boolean(deal?.sentAt)"
        />

        <Button
          label="Save"
          @click="() => {
            updateDealTalentSelection({
              id: selectedTalent,
              data: {
                agreedRatecard: Number(agreedRatecard) as number,
                talentPresentation: talentPresentation as string,
              },
            });
            isEditModalActive = false;
          }"
          :disabled="Boolean(deal?.sentAt)"
        />
      </div>
    </template>
  </Modal>
  <div class="talentPool">
    <div class="overview">
      <span>Talent pool</span>
    </div>
    <div class="actions">
      <Tab
        :items="tabItems"
        :initial-active="tab as string"
        @clicked="(value) => setTab(value)"
        class="tab"
        :bg-color="colors[tab as string].bgColor"
        :text-color="colors[tab as string].textColor"
      />
      <div class="actions-buttons">
        <Button
          v-if="tab === 'POOL'"
          :label="isAutoMatchingLoading ? 'Loading...' : 'TRS Matching'"
          @click="isAutoMatchingModalActive = true"
          :disabled="isAutoMatchingLoading"
          variant="accent"
        />
        <Button
          v-if="tab === 'POOL'"
          label="Add talent"
          :to="`/projects/addTalents/${id}?status=ELIGIBLE&scope=${deal?.scope}`"
          tag="a"
          :disabled="Boolean(deal?.sentAt)"
        />
        <Button
          variant="accent"
          label="Send to Zoho"
          v-if="tab === 'SHORTLIST'"
          @click="isSendDealModalActive = true"
          :disabled="
            Boolean(deal?.sentAt) ||
            talentsSelection?.dealTalentSelections.nodes
              ?.filter((talent) => talent.status === 'SHORTLIST')
              ?.some((talent) => !talent.talentPresentation || !talent.agreedRatecard)
          "
        />
      </div>
    </div>
    <Talent
      v-for="talent in talents(tab as string)"
      v-if="talentsSelection && !isAutoMatchingLoading"
      :key="talent.id"
      :talent="talent"
      @set-selected-talent-status="(value) => setSelectedTalentStatus(value)"
      @open-status-modal="isChangeStatusModalActive = true"
      @open-notes-modal="isNoteModalActive = true"
      @open-edit-modal="isEditModalActive = true"
      @open-talent-count-modal="isTalentCountModalActive = true"
      @set-talent-count-modal-status="(value: string) => (talentCountModalStatus = value)"
      @set-selected-talent="(talent) => (selectedTalent = talent)"
      :reset-select="resectSelect"
      @delete-talent-selection="
        (ids) => {
          deleteIds = ids;
          isDeleteModalActive = true;
        }
      "
    />
    <div class="skeletons" v-if="!talentsSelection || isAutoMatchingLoading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.changeStatus {
  font-size: 2rem;
  color: $black;
  font-weight: $primary-font-weight;
  font-family: $primary-font-family;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.talentPool {
  border: 1px solid $black-10;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;

  .tab {
    margin-bottom: 2rem;
  }

  .overview {
    display: flex;
    justify-content: space-between;
    & > span {
      text-transform: uppercase;
      color: $black-50;
      font-size: 1.3rem;
      margin-left: auto;
      line-height: 5px;
    }
    padding-bottom: 0.5rem;
  }
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.editModal {
  height: 100%;
}
.actions {
  display: flex;
  justify-content: space-between;

  .actions-buttons {
    display: flex;
    gap: 1rem;
  }
}
.skeletons {
  padding: 3rem 0 3rem 0;
  & > div {
    background-color: $black-10;
    border-radius: 0.4rem;
    height: 80px;
    margin-bottom: 2rem;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
}
</style>
