<script setup lang="ts">
import { computed, toRefs, onMounted } from 'vue';

type IIconProps = {
  icon: string;
  iconSize?: 'micro' | 'small' | 'medium' | 'large';
};

const props = withDefaults(defineProps<IIconProps>(), {
  iconSize: 'small',
});

const { iconSize, icon } = toRefs(props);

const iconName = computed(() => {
  const url = new URL(`@/assets/icons/icons.svg#`, import.meta.url).href;

  return url + icon.value;
});
</script>

<template>
  <span class="icon">
    <svg :class="`icon__svg --${iconSize}`">
      <svg
        id="idcard"
        viewBox="0 0 256 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-if="iconName === 'idcard'"
      >
        <path
          d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"
          fill="currentColor"
        />
      </svg>
      <use :xlink:href="iconName" v-else />
    </svg>
  </span>
</template>

<style scoped lang="scss">
.icon {
  display: inline-flex;
  align-items: center;

  &__svg {
    height: 1.6rem;
    width: 1.6rem;
    color: currentColor;
    fill: currentColor;
  }

  .--large {
    height: 6.4rem;
    width: 6.4rem;
  }

  .--medium {
    height: 2.4rem;
    width: 2.4rem;
  }

  .--micro {
    height: 1rem;
    width: 1rem;
  }
}
</style>
