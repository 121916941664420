<script setup lang="ts">
import { defineProps } from 'vue';
import { getDeal } from '@/api/queries/deal';
import Button from '@/components/atoms/Button.vue';
const props = defineProps<{
  project: any;
}>();

const { data: deal } = getDeal({
  id: props.project,
});
</script>
<template>
  <div class="container">
    <div>
      <div class="badge">{{ deal?.deal.stage }}</div>
      <div class="title-container">
        <span>{{ deal?.deal.title }}</span>
      </div>
      <div class="company-name">{{ deal?.deal.companyName }}</div>
    </div>
    <Button
      icon="search-doc"
      variant="accent"
      tag="a"
      :to="`/projects/${deal?.deal.id}`"
      target="_blank"
      class="viewTalent"
    />
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 1rem;
  font-size: 1.5rem;
  align-items: center;
  justify-content: space-between;
  .title-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .badge {
    background-color: $blue;
    color: $white;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    width: fit-content;
    height: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .company-name {
    color: #808080;
  }
}
</style>
