<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';

defineProps<{
  isModalActive: boolean;
  head: string;
  icon?: boolean;
  bgColor?: string;
  textColor?: string;
}>();

const emit = defineEmits(['closeModal']);

function close() {
  emit('closeModal');
}
</script>

<template>
  <o-modal
    class="Modal"
    :active="isModalActive"
    :on-cancel="close"
    scroll="keep"
    :style="{ '--modal-bg-color': bgColor || 'black', '--modal-text-color': textColor || 'white' }"
  >
    <div class="Modal__header">
      <h4 class="Modal__header-title heading-h4">{{ head }}</h4>
      <Icon class="Modal__header-icon" icon="close" v-if="icon" @click="close"></Icon>
    </div>
    <div class="Modal__content">
      <div class="Modal__content-main">
        <slot name="modal-main"></slot>
      </div>
      <div class="Modal__content-footer">
        <slot name="modal-footer"></slot>
      </div>
    </div>
  </o-modal>
</template>

<style lang="scss" scoped>
.Modal {
  &__header {
    padding: 1.3rem;
    background-color: var(--modal-bg-color);
    position: relative;
    &-title {
      color: var(--modal-text-color);
      margin: 0;
      text-align: center;
    }

    &-icon {
      position: absolute;
      top: 1.8rem;
      right: 2.4rem;
      color: $secondary-color;
      cursor: pointer;
    }
  }

  &__content {
    padding: 2.5rem 0;
    &-main {
      max-height: 55rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.8rem;
        left: -10rem;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary-color;
        border-radius: 4rem;
      }
    }

    &-footer {
      @include flexing(row, center, center);
      gap: 1.6rem;
    }
  }

  :deep .o-modal {
    &__content {
      min-width: 66rem;
    }
  }
}
</style>
