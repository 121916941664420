<script setup lang="ts">
import Modal from '@/components/atoms/Modal.vue';
import Project from './Project.vue';
import { defineProps } from 'vue';

const props = defineProps<{
  isModalActive: boolean;
  status: string;
  talent: any;
}>();

const statusesCounts: Record<string, string> = {
  longlist: 'longListCount',
  rejected: 'rejectedCount',
  notAvailable: 'notAvailableCount',
  maybe: 'maybeCount',
  shortlist: 'shortlistCount',
};

const titles: Record<string, string> = {
  longlist: 'Longlist',
  rejected: 'Rejected',
  notAvailable: 'Not Available',
  maybe: 'Maybe',
  shortlist: 'Shortlist',
};

const emit = defineEmits(['closeModal']);

const colors: Record<string, { bgColor: string; textColor: string }> = {
  longlist: {
    bgColor: '#ede9fe',
    textColor: '#6d28d9',
  },
  rejected: {
    bgColor: '#fce7f3',
    textColor: '#a21caf',
  },
  notAvailable: {
    bgColor: '#ecfccb',
    textColor: '#4d7c0f',
  },
  maybe: {
    bgColor: '#fef3c7',
    textColor: '#a16207',
  },
  shortlist: {
    bgColor: '#e0f2fe',
    textColor: '#0369a1',
  },
};
</script>

<template>
  <Modal
    :head="`${props.talent.talent.firstName} ${props.talent.talent.lastName} - ${titles[status]}`"
    :is-modal-active="isModalActive"
    @close-modal="emit('closeModal')"
    :bg-color="colors[status].bgColor"
    :text-color="colors[status].textColor"
  >
    <template #modal-main>
      <div v-for="project in props.talent[statusesCounts[status]]" :key="project.id">
        <Project :project="project" />
      </div>
    </template>
  </Modal>
</template>
